import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Pendukung extends BaseModel {
  fields =  {
    'caleg_id': {
      type: 'enum',
      label: 'Caleg',
      rules: 'required',
      options: {}
    },
    'nik': {
      type: 'text',
      label: 'NIK',
      rules: 'required'
    },
    'nkk': {
      label: 'NKK',
      type: 'text',
      rules: 'required'
    },
    'name': {
      label: 'Nama',
      type: 'text',
      rules: 'required'
    },
    'phone_number': {
      type: 'text',
      label: 'No. HP',
      rules: 'required'
    },
    'city_id': {
      type: 'enum',
      label: 'Kabupaten',
      rules: 'required',
      options: {}
    },
    'district_id': {
      type: 'enum',
      label: 'Kecamatan',
      rules: 'required',
      options: {}
    },
    'village_id': {
      type: 'enum',
      label: 'Kelurahan',
      rules: 'required',
      options: {}
    },
    'tps': {
      label: 'TPS',
      type: 'number',
      rules: 'required'
    },
    'hometown': {
      type: 'text',
      label: 'Tempat Lahir',
      rules: 'required'
    },
    'dob': {
      type: 'date',
      label: 'Tanggal Lahir',
      rules: 'required'
    },
    'age': {
      label: 'Umur',
      type: 'number',
      rules: 'required'
    },
    'marriage_status': {
      type: 'enum',
      label: 'Status',
      options: {
        'S': 'Sudah Menikah',
        'B': 'Belum Menikah',
        'CH': 'Cerai Hidup',
        'CM': 'Cerai Mati',
      },
      rules: 'required'
    },
    'gender': {
      type: 'radio',
      label: 'Jekel',
      rules: 'required',
      options: {
        'laki-laki': 'Laki-Laki',
        'perempuan': 'Perempuan',
      }
    },
    'address': {
      type: 'textarea',
      label: 'Alamat',
      rules: 'required'
    },
  }
  endpoint = process.env.VUE_APP_API_URL + 'pendukung'

  checkNIK (nik, calegId) {
    return new Promise((resolve, reject) => {
      axios.post(this.endpoint + '/check-nik/' + nik + '?caleg_id=' + calegId).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  getCalegDetail (id) {
    return new Promise((resolve, reject) => {
      axios.get(this.endpoint + '/' + id + '/caleg-detail').then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }

  attachCaleg (dptId, calegId, additionals) {
      return new Promise((resolve, reject) => {
        axios.post(this.endpoint + '/caleg/attach', {
          dpt_id: dptId,
          caleg_id: calegId,
          additionals: additionals
        }).then(response => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
  }
}