<template>
    <div style="max-width: 550px; margin: auto;">
        <h2 class="text-center">Pilih Salah Satu</h2>
        <b-row class="mt-3">
            <b-col class="parliament-cards" v-for="parliamentRegion in parliamentRegions" cols="12" md="4" :key="parliamentRegion.id">
                <router-link :to="'/caleg?parliament_region_id=' + parliamentRegion.id">
                    <img :src="parliamentRegion.level === 1 ? './assets/images/logo-dpr.jpeg' : './assets/images/logo-dprd.png'" class="img-fluid" alt="">
                    <h4 class="text-center">{{ parliamentRegion.name }}</h4>
                </router-link>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ParliamentRegion from '@/models/ParliamentRegion.js'

const parliamentRegionModel = new ParliamentRegion()

export default {
  data () {
    return {
      parliamentRegions: [],
      isLoading: false,
    }
  },
    mounted () {
        this.getParliamentRegions()
    },
    methods: {
      getParliamentRegions () {
        parliamentRegionModel.list({
            'limit': 100,
            'order': 'level',
            'ascending': 1
        }).then(resp => {
            this.parliamentRegions = resp.data
        })
      },
    }
}
</script>