<template>
  <div>
    <b-card no-header>
      <b-row v-if="party" class="mb-4">
        <b-col cols="6" md="2">
          <h2 class="m-0 text-md-center text-lg-center" style="font-weight: bold;">
            <img v-if="party.logo && Object.keys(party.logo).length > 0" :src="party.logo['']" alt="" style="max-width: 70px;"> {{ party.name }}
          </h2>
        </b-col>
        <b-col cols="6" class="text-right d-md-none d-lg-none">
          <b-button-group class="add-button-group">
            <b-btn variant="success" type="button" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
          </b-button-group>
        </b-col>
        <b-col cols="12" md="10" class="mt-2 mt-md-0">
          <b-row>
            <b-col cols="12" class="text-right d-none d-md-block d-lg-block">
              <b-button-group class="add-button-group">
                <b-btn variant="success" type="button" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
              </b-button-group>
            </b-col>
            <b-col cols="12" class="text-right">
              <b-form @submit.prevent="doFilter">
                <b-row class="filter-row justify-content-end">
                  <b-col cols="6" md="4">
                    <form-group-input ref="filter-name" v-model="filter.name" type="text" placeholder="" :need-label="true" label="cari nama caleg" id="filter-name" class="text-left" />
                  </b-col>
                  <b-col cols="6" md="4" v-if="detail.parliamentRegion && detail.parliamentRegion.level === 3" v-show="list.city.length > 0">
                    <div id="wrapper-input-filter-name" class="mb-3 text-left">
                      <div>
                        <label for="filter-name" class="form-label">Cari Kab</label>
                        <v-select placeholder="Pilih Kab" v-model="filter.dapil_city_id" :disabled="list.city.length < 1" :reduce="option => option.value" :options="list.city" :clearable="false"></v-select>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="3" md="2" v-show="list.dapil.length > 0">
                    <div id="wrapper-input-filter-name" class="mb-3 text-left">
                      <div>
                        <label for="filter-name" class="form-label">Cari Dapil</label>
                        <v-select placeholder="Pilih Dapil" v-model="filter.dapil_id" :disabled="list.dapil.length < 1" :reduce="option => option.value" :options="list.dapil" :clearable="false"></v-select>
                      </div>
                    </div>
                    <!-- <form-group-input ref="filter-tps" type="text" class="text-left" placeholder="" :need-label="true" label="TPS" id="filter-tps" /> -->
                  </b-col>
                  <b-col cols="2" md="1" style="max-width: 165px; text-align: right;">
                    <span><label class="form-label">&nbsp;</label><br></span>
                    <b-btn type="submit" variant="primary" class="mr-0 btn-in-form"><font-awesome-icon icon="search"/><span>Cari</span></b-btn>
                    <!-- <b-btn type="submit" @click.prevent="clearForm" variant="secondary" class="btn-in-form d-none d-md-inline"><font-awesome-icon icon="broom"/><span>Clear</span></b-btn> -->
                  </b-col>
                </b-row>
              </b-form>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
      
      <b-row class="mb-2">
      <b-col cols="12" md="6">
        <h5 class="m-0">
          {{pageTitle}}
        </h5>
      </b-col>
      <b-col cols="12" md="6">
        <p class="m-0 text-right">Total Suara: {{ $formatPrice(totalVote) }}</p>
      </b-col>
    </b-row>
      
    <paginated-table v-if="hasLoaded" ref="dataTable" :delete-reason="false" :can-delete="false" :can-edit="false" :data-url="apiUrl.caleg" :data-columns="columns" :no-actions="true" :dataParams="dataParams" :defaultDataParams="this.filter" @loaded="onTableLoaded">
      <template slot="content_role" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.role !== null ? defaultSlotScope.colData.role.replace('-', ' ') : ''}}</span>
      </template>
      <template slot="content_phone_number" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_detail !== null ? defaultSlotScope.colData.user_detail.phone_number.replace('+62', '0') : ''}}</span>
      </template>
      <template slot="content_dapil" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_work_area !== null && defaultSlotScope.colData.user_work_area.dapil !== null ? defaultSlotScope.colData.user_work_area.dapil.name : ''}}</span>
      </template>
      <template slot="content_work_area_city_id" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_work_area !== null && defaultSlotScope.colData.user_work_area.work_area_city_id !== null ? defaultSlotScope.colData.user_work_area.city.name : ''}}</span>
      </template>
      <template slot="content_work_area_district_id" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_work_area !== null && defaultSlotScope.colData.user_work_area.work_area_district_id !== null ? defaultSlotScope.colData.user_work_area.district.name : ''}}</span>
      </template>
      <template slot="content_work_area_village_id" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_work_area !== null && defaultSlotScope.colData.user_work_area.work_area_village_id !== null ? defaultSlotScope.colData.user_work_area.village.name : ''}}</span>
      </template>
      <template slot="content_number" slot-scope="defaultSlotScope">
        <span>{{defaultSlotScope.colData.user_work_area.number}}</span>
      </template>
      <template slot="content_party_id" slot-scope="defaultSlotScope">
        <span>{{defaultSlotScope.colData.user_work_area !== null && defaultSlotScope.colData.user_work_area.party_id !== null ? defaultSlotScope.colData.user_work_area.party.name : ''}}</span>
      </template>
      <template slot="content_vote_count" slot-scope="defaultSlotScope">
        <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_work_area !== null ? defaultSlotScope.colData.user_work_area.vote_count : ''}}</span>
      </template>

      <template slot="content_name" slot-scope="defaultSlotScope">
        <router-link v-if="defaultSlotScope.colData.username" style="text-decoration: underline;" :to="'/perolehan-suara/calon/' + $route.params.regionId + '/' + $route.params.partyid + '/caleg/' + defaultSlotScope.colData.id" class="mb-0 text-center detail mr-1">
          {{ defaultSlotScope.colData.name }}
        </router-link>
        <span v-else>{{ defaultSlotScope.colData.name }}</span>
      </template>

      <template slot="actionButtons" slot-scope="defaultSlotScope">
        <b-row v-if="defaultSlotScope.colData.username" class="justify-content-center table-actions">
          <template>
            <b-button variant="primary" size="sm" :to="'/perolehan-suara/calon/' + $route.params.regionId + '/' + $route.params.partyid + '/caleg/' + defaultSlotScope.colData.id" class="mb-0 text-center detail mr-1">
              <font-awesome-icon icon="info"/>
            </b-button>
          </template>
        </b-row>
      </template>
    </paginated-table>
    </b-card>
  </div>
</template>

<script>
import formGroupInput from '@/components/FormGroupInput.vue'
import paginatedTable from '@/components/PaginatedTable.vue'
import Dapil from '@/models/Dapil.js'
import City from '@/models/City.js'
import Party from '@/models/Party.js'
import User from '@/models/User.js'
import ParliamentRegion from '@/models/ParliamentRegion.js'

const dapilModel = new Dapil()
const cityModel = new City()
const partyModel = new Party()
const userModel = new User()
const parliamentRegionModel = new ParliamentRegion()


export default {
  components: {
    paginatedTable,
    formGroupInput
  },
  data () {
    return {
      parties: [],
      party: null,
      isLoading: false,
      hasLoaded: false,
      totalVote: 0,
      pageTitle: null,
      apiUrl: {
        caleg: userModel.endpoint
      },
      columns: [
        {
          prop: 'name',
          label: 'Nama',
          sortable: true,
          resizable: true,
          minWidth: 200
        },
        {
          prop: 'number',
          label: 'No. Urut',
          resizable: true,
          sortable: true,
          align: 'center',
          minWidth: 100
        },
        {
          align: 'center',
          prop: 'dapil',
          label: 'DAPIL',
          resizable: true,
          sortable: true,
          minWidth: 80
        },
        {
          align: 'center',
          prop: 'vote_count',
          label: 'Jlh Suara',
          resizable: true,
          sortable: true,
          minWidth: 80
        },
        // {
        //   align: 'center',
        //   prop: 'actionButtons',
        //   label: '',
        //   resizable: true,
        //   sortable: true,
        //   minWidth: 50
        // },
      ],
      filter: {
        dapil_city_id: typeof this.$route.query.dapil_city_id !== 'undefined' ? this.$route.query.dapil_city_id : null,
        with_user_detail: 1,
        // only_has_access: 1,
        with_user_work_area: 1,
        party_id: this.$route.params.partyid,
        parliament_region_id: this.$route.params.regionId,
        roles: 'caleg',
        name: typeof this.$route.query.name !== 'undefined' ? this.$route.query.name : null,
        dapil_id: typeof this.$route.query.dapil_id !== 'undefined' ? this.$route.query.dapil_id : null,
      },
      list: {
        dapil: [],
        city: [],
      },
      detail: {
        parliamentRegion: null
      },
      dataParams: ['with_user_detail', 'with_user_work_area', 'name', 'dapil_id', 'tps', 'work_area_city_id', 'work_area_district_id', 'work_area_village_id', 'roles', 'number', 'party_id', 'only_has_access', 'parliament_region_id', 'dapil_city_id']
    }
  },
  mounted () {
    this.getPartyDetail()
    this.getParliamentRegionDetail()
  },
  watch: {
    '$route.params.partyid': function (val) {
      if (val !== undefined) {
        this.getPartyDetail()
      }
    },
    'filter.dapil_city_id': function () {
      if (this.detail.parliamentRegion.level === 3) {
        this.filter.dapil_id = null
        this.getDapil()
      }
    }
  },
  methods: {
    getPartyDetail () {
      partyModel.find(this.$route.params.partyid).then(resp => {
        this.party = resp
      })
    },
    clearForm () {
      this.$nextTick(() => {
        this.filter = {
          dapil_city_id: null,
          with_user_detail: 1,
          // only_has_access: 1,
          with_user_work_area: 1,
          party_id: this.$route.params.partyid,
          parliament_region_id: this.$route.params.regionId,
          roles: 'caleg',
          name: null,
          dapil_id: null,
        }
        setTimeout(() => {
          this.doFilter()
        }, 100)
      })
    },
    doFilter () {
      this.$refs.dataTable.doFilter(this.filter)
    },
    getCities () {
      cityModel.list({
        'name': 'labuhan',
        'province_id': '950b9d3a-c996-4207-97e7-5a2858981979',
        'limit': 10000
      }).then(resp => {
        const options = []
        resp.data.forEach(d => {
          options.push({
            value: d.id,
            label: d.name
          });
        });
        this.list.city = options

        if (!this.hasLoaded) {
          if (typeof this.$route.query.dapil_id !== 'undefined') {
            this.getDapil()
          }
        }
        if (typeof this.$route.query.dapil_city_id !== 'undefined') {
          this.filter.dapil_city_id = this.$route.query.dapil_city_id
        } else {
          this.filter.dapil_city_id = resp.data[0].id
        }
        // 
      })
    },
    getParliamentRegionDetail () {
      parliamentRegionModel.find(this.$route.params.regionId).then(resp => {
        this.detail.parliamentRegion = resp
        this.pageTitle = 'List ' + resp.name
        if (resp.level == 3) {
          this.getCities()
        } else {
          this.getDapil()
        }
      })
    },
    getDapil () {
      // if (this.detail.parliamentRegion === this.de.parliamentRegions[2].value && !this.model.city) {
      //   this.list.dapil = []
      //   return false;
      // }
      dapilModel.list({
        'region_city_id': this.detail.parliamentRegion.level === 3 ? this.filter.dapil_city_id : '',
        'limit': 100,
        'parliament_region_id': this.$route.params.regionId
      }).then(resp => {
        const options = []
        resp.data.forEach(d => {
          options.push({
            value: d.id,
            label: d.name
          });
        });
        this.list.dapil = options

        if (!this.hasLoaded) {
          this.filter.dapil_id = typeof this.$route.query.dapil_id !== 'undefined' ? this.$route.query.dapil_id : resp.data[0].id
          this.hasLoaded = true;
          setTimeout(() => {
            // this.doFilter()
          }, 100)
        } else {
          this.filter.dapil_id = resp.data[0].id
        }
      })
    },
    onTableLoaded (resp) {
      let total = 0
      resp.data.forEach(data => {
        total += parseInt(data.user_work_area.vote_count)
      });
      this.totalVote = total
    },
    doExport () {
      if (this.isLoading) {
        return false;
      }
      this.isLoading = true;
      const filterArray = [];
      Object.keys(this.filter).forEach(key => {
        if (this.filter[key]) {
          filterArray.push(key + "=" + this.filter[key]);
        }
      });
      filterArray.push('csv=1');

      this.$http.get(userModel.getEndpoint() + "?" + filterArray.join("&")).then(response => {
        const a = document.createElement("a");

        const blob = new Blob([response.data], {type: "octet/stream"});
        a.href = window.URL.createObjectURL(blob);

        a.download = "data_perolehan_suara.csv";
        a.click();
        this.isLoading = false;
      }).catch(error => {
        this.$store.dispatch('notification/error', error)
        this.isLoading = false;
      })
    }
  }
}
</script>