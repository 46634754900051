<template>
    <b-card no-header no-footer>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
              <b-row>
                <b-col cols="12" md="6">
                  <h5>Informasi Pribadi</h5>
                  <template v-for="(m, index) in filterModel(['name', 'nik', 'gender', 'hometown', 'dob', 'city_id', 'district_id', 'village_id', 'address', 'phone_number'])">
                    <div :key="'pribadi_' + index">
                      <div v-if="index === 'district_id'">
                        <form-group-input v-show="userModel.city_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                      </div>
                      <div v-else-if="index === 'village_id'">
                        <form-group-input v-show="userModel.district_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                      </div>
                      <form-group-input v-else :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                    </div>
                    
                  </template>
                </b-col>
                <b-col cols="12" md="6">
                  <h5>Informasi Tugas</h5>
                  <template v-for="(m, index) in filterModel(['role', 'username', 'password', 'dapil_id', 'work_area_city_id', 'work_area_district_id', 'work_area_village_id', 'tps'])">
                    <div :key="'tugas_' + index">
                      <div v-if="index === 'work_area_district_id'">
                        <form-group-input v-show="userModel.work_area_city_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                      </div>
                      <div v-else-if="index === 'work_area_village_id'">
                        <form-group-input v-show="userModel.role.value === 'petugas-tps' && userModel.work_area_district_id.value !== null" :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                      </div>
                      <form-group-input v-else :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :options="m.type === 'radio' ? getClassicOptions(index) : getOptions(index)" :need-label="true" v-model="m.value" :rules="typeof m.rules !== 'undefined' ? m.rules : null" :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null" :id="index" />
                      <small v-if="mode === 'update' && index === 'password'" style="margin-top: -1rem !important; display: block;" class="mb-3">Kosongkan jika tidak ingin mengubah password.</small>
                    </div>
                    
                  </template>
                </b-col>
              </b-row>
              <div>
                <b-button type="submit" variant="primary" class="mt-0"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
                <b-button :to="'/users'" variant="secondary" class="ml-3 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Batal</b-button>
              </div>
            </form>
        </ValidationObserver>
      </b-card>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions, classicObjectToOptions } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import User from '@/models/User.js'
import City from '@/models/City.js'
import District from '@/models/District.js'
import Village from '@/models/Village.js'
import Dapil from '@/models/Dapil.js'

const userModel = new User()
const cityModel = new City()
const districtModel = new District()
const villageModel = new Village()
const dapilModel = new Dapil()

export default {
  components: {
    formGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (this.mode === 'update') {
        this.getDetail()
      }
    },
    'userModel.role.value': async function (val) {
      this.setHideModels(val)
    },
    'userModel.city_id.value': async function (val, oldVal) {
      if (val) {
        this.userModel.district_id.options = await this.getDistricts(val)
        this.totalPromiseAreaCount++;
        if (val !== oldVal && !this.initEdit) {
          this.userModel.district_id.value = null
        }
      } else {
        this.userModel.district_id.value = null
      }
    },
    'userModel.district_id.value': async function (val, oldVal) {
      if (val) {
        this.userModel.village_id.options = await this.getVillages(val)
        this.totalPromiseAreaCount++;
        if (val !== oldVal && !this.initEdit) {
          this.userModel.village_id.value = null
        }
      } else {
        this.userModel.village_id.value = null
      }
    },
    'userModel.work_area_city_id.value': async function (val, oldVal) {
      if (val) {
        this.userModel.work_area_district_id.options = await this.getDistricts(val)
        this.userModel.dapil_id.options = await this.getDapil(val)
        this.totalPromiseAreaCount++;
        if (val !== oldVal && !this.initEdit) {
          this.userModel.work_area_district_id.value = null
          this.userModel.dapil_id.value = null
        }
      } else {
        this.userModel.work_area_district_id.value = null
        this.userModel.dapil_id.value = null
      }
    },
    'userModel.work_area_district_id.value': async function (val, oldVal) {
      if (val) {
        if (this.userModel.role.value === 'petugas-tps') {
          this.userModel.work_area_village_id.options = await this.getVillages(val)
        }
        this.totalPromiseAreaCount++;
        if (val !== oldVal && !this.initEdit) {
          this.userModel.work_area_village_id.value = null
        }
      } else {
        this.userModel.work_area_village_id.value = null
      }
      // this.setHideModels(this.userModel.role.value)
    },
    'totalPromiseAreaCount': async function (val) {
      if (val >= 4) {
        this.initEdit = false;
      }
    },
  },
  computed: {
    mode: function () {
      return this.$route.params.id !== undefined ? 'update' : 'create'
    }
  },
  data () {
    return {
      totalPromiseAreaCount: 0,
      initEdit: false,
      isLoading: false,
      userModel: userModel.init()
    }
  },
  mounted () {
    if (this.mode === 'update') {
      this.userModel.password.rules = null
      this.getDetail()
    }
    this.getCities()
  },
  methods: {
    setHideModels (val) {
      if (val === 'petugas-tps') {
        this.userModel.work_area_village_id.rules = 'required'
        this.userModel.tps.rules = 'required'
        if (document.querySelector('#wrapper-input-work_area_village_id')) {
          document.querySelector('#wrapper-input-work_area_village_id').style.display = "block";
        }
        if (document.querySelector('#wrapper-input-tps')) {
          document.querySelector('#wrapper-input-tps').style.display = "block";
        }
      } else {
        this.userModel.work_area_village_id.rules = null;
        this.userModel.tps.rules = null;
        if (document.querySelector('#wrapper-input-work_area_village_id')) {
          document.querySelector('#wrapper-input-work_area_village_id').style.display = "none";
        }
        if (document.querySelector('#wrapper-input-tps')) {
          document.querySelector('#wrapper-input-tps').style.display = "none";
        }
      }
    },
    filterModel (search) {
      const filtered = Object.entries(this.userModel).filter(mdl => {
        return search.includes(mdl[0])
      });
      let result = {}
      filtered.forEach(element => {
        result[element[0]] = element[1];
      });
      return result;
    },
    getCities () {
      cityModel.list({
        'name': 'labuhan',
        'province_id': '950b9d3a-c996-4207-97e7-5a2858981979',
        'limit': 10000
      }).then(resp => {
        let data = []
        // data[null] = 'Tidak Ada'
        resp.data.forEach(d => {
          data[d.id] = d.name
        })
        this.userModel.city_id.options = data
        this.userModel.work_area_city_id.options = data
      })
    },
    async getDistricts (cityId) {
      return new Promise(resolve => {
        districtModel.list({
          'city_id': cityId
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          resolve(data);
          // this.userModel.district_id.options = data
        })
      })
    },
    async getVillages (districtId) {
      return new Promise(resolve => {
        villageModel.list({
          'district_id': districtId,
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          resolve(data);
          // this.userModel.village_id.options = data
        })
      })
    },
    async getDapil (cityId) {
      return new Promise(resolve => {
        dapilModel.list({
          'region_city_id': cityId,
          'parliament_region_level': 3,
          'limit': 200
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          resolve(data);
          // this.userModel.village_id.options = data
        })
      })
    },
    getOptions (key) {
      return typeof this.userModel[key].options !== 'undefined' ? objectToOptions(this.userModel[key].options) : null
    },
    getClassicOptions (key) {
      return typeof this.userModel[key].options !== 'undefined' ? classicObjectToOptions(this.userModel[key].options) : null
    },
    getDetail () {
      this.initEdit = true
      this.isLoading = true
      userModel.find(this.$route.params.id, {
        detail: 1
      }).then(resp => {
        this.isLoading = false
        let response = {}
        // response = resp
        Object.keys(resp.user_detail).forEach(key => {
          if (!['user_id', 'village', 'city', 'district', 'province'].includes(key)) {
            response[key] = resp.user_detail[key]
          }
        });
        Object.keys(resp.user_work_area).forEach(key => {
          if (!['user_id', 'village', 'city', 'district', 'province'].includes(key)) {
            response[key] = resp.user_work_area[key]
          }
        });
        Object.keys(resp).forEach(key => {
          if (!['user_detail', 'user_work_area'].includes(key)) {
            response[key] = resp[key]
          }
        });
        response['phone_number'] = response['phone_number'].replace('+62', '0');
        setModelValue(this.userModel, response)
        Object.keys(this.userModel).forEach(key => {
          // if (key === 'role') {
          //   this.$refs[key][0].setValue(resp.roles[0].name)
          // } else {
          //   this.$refs[key][0].setValue(this.userModel[key].value)
          // }
          if (typeof this.$refs[key] !== 'undefined') {
            this.$refs[key][0].setValue(this.userModel[key].value)
          }
          // setTimeout(() => {
          //   this.initEdit = false
          // }, 500)
        })
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        // this.initEdit = false
        this.isLoading = false
      })
    },
    resetForm () {
      this.$nextTick(() => {
        // clearModelValue(this.userModel)
        Object.keys(this.userModel).forEach(key => {
          this.$refs[key][0].setValue(null)
        })
        this.$refs.form.reset()
      })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form)
          return false
        }
        saveProcess(this.userModel, this.mode === 'update').then(model => {
          this.isLoading = true
          if (this.mode === 'update') {
            userModel.update(this.$route.params.id, model).then(() => {
              this.isLoading = false
              this.$store.dispatch('notification/success', 'Data user berhasil diubah.')
              this.$router.push('/users/' + this.$route.params.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          } else {
            userModel.create(model).then(resp => {
              this.isLoading = false
              // this.resetForm()
              this.$store.dispatch('notification/success', 'Data user berhasil disimpan.')
              this.$router.push('/users/' + resp.id)
            }).catch(error => {
              this.isLoading = false
              this.$store.dispatch('notification/error', error)
            })
          }
        }).catch(() => {})
      })
    }
  }
}
</script>