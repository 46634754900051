<template>
  <div>
      <!-- <div class="mb-4">
        <h5 class="text-center">
          Rasio Pengguna Hak Suara
        </h5>
        <b-progress class="mt-3" :max="100" height="2rem">
          <b-progress-bar :value="20">
            <span>20%</span>
          </b-progress-bar>
        </b-progress>
      </div> -->
      <b-row class="mb-4">
        <b-col cols="12" md="2">
          <v-select placeholder="Pilih Regional" v-model="model.parliamentRegion" :disabled="list.parliamentRegions.length < 1" :reduce="option => option.value" :options="list.parliamentRegions" :clearable="false"></v-select>
        </b-col>
        <b-col cols="12" md="3" v-if="model.parliamentRegion && model.parliamentRegion === list.parliamentRegions[2].value">
          <v-select placeholder="Pilih Kab" v-model="model.city" :disabled="list.city.length < 1" :reduce="option => option.value" :options="list.city" :clearable="false"></v-select>
        </b-col>
        <b-col cols="12" md="3">
          <v-select placeholder="Pilih Dapil" v-model="model.dapil" :disabled="list.dapil.length < 1" :reduce="option => option.value" :options="list.dapil" :clearable="false"></v-select>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="12" md="3" class="d-inline-flex align-items-center">
          <p class="m-0">Jumlah Pengguna Hak Suara: <strong>{{ $formatPrice(model.totalVoteUse) }}</strong></p>
        </b-col>
        <b-col cols="12" md="3" class="d-inline-flex align-items-center">
          <p class="m-0">Total DPT: <strong>{{ $formatPrice(model.totalDpt) }}</strong></p>
        </b-col>
      </b-row>

      <b-row class="mb-0">
        <b-col cols="4" md="2" class="mb-3" v-for="party in list.parties" :key="party.id">
          <!-- <router-link class="card-d-link" :to="'parties/' + party.id + '/' + model.parliamentRegion + (model.dapil ? '/' + model.dapil : '')"> -->
            <b-card :header="!loading.loadVote ? ( 'Suara: ' + ($formatPrice(party.total_vote_count)) ) : ''" class="party-dashboard-list h-100 d-flex align-items-center justify-content-center" body-class="d-flex align-items-center justify-content-center">
              <img :src="party.logo['']" class="img-fluid" alt="">
            </b-card>
          <!-- </router-link> -->
        </b-col>
      </b-row>
  </div>
</template>

<script>
import Dpt from '@/models/Dpt.js'
import City from '@/models/City.js'
import Party from '@/models/Party.js'
import Dapil from '@/models/Dapil.js'
import ParliamentRegion from '@/models/ParliamentRegion.js'

const dptModel = new Dpt()
const cityModel = new City()
const partyModel = new Party()
const dapilModel = new Dapil()
const parliamentRegion = new ParliamentRegion()

export default {
  data () {
    return {
      loading: {
        loadVote: false,
      },
      model: {
        totalVoteUse: 0,
        totalDpt: 0,
        dapil: null,
        city: null,
        parliamentRegion: null
      },
      list: {
        parties: [],
        city: [],
        party_vote_counts: [],
        dapil: [],
        parliamentRegions: []
      }
    }
  },
  mounted () {
    if (this.$store.getters['account/accountData'].role === 'petugas-tps') {
      this.$router.push('/input-suara/calon/2')
    } else {
      this.$router.push('/kelurahan-suara')
    }
    // this.getCities()
    // this.getParties()
    // this.getParliamentRegions()
  },
  watch: {
    'model.parliamentRegion': function (newValue) {
      this.model.dapil = null
      if (newValue) {
        if (this.model.parliamentRegion !== this.list.parliamentRegions[2].value) {
          this.model.city = null
        } else if (this.model.parliamentRegion === this.list.parliamentRegions[2].value) {
          this.model.city = this.list.city[0].value
        }

        this.getDapil()
        this.getTotalDPt()
        this.getTotalVote()

      }
    },
    'model.dapil': function (newValue) {
      if (newValue) {
        this.getTotalDPt()
        this.getTotalVote()
      }
    },
    'model.city': function (newValue) {
      this.model.dapil = null
      if (newValue) {
        this.getDapil()
      }
    },
  },  
  methods: {
    getParties () {
      partyModel.list({
        'limit': 10000
      }).then(resp => {
        const partyList = []
        resp.data.forEach(d => {
          partyList.push({
            ... d,
            total_vote_count: 0
          })
        });
        this.list.parties = partyList
      })
    },
    getParliamentRegions () {
      parliamentRegion.list({
        'limit': 3
      }).then(resp => {
        const options = []
        resp.data.forEach(d => {
          options.push({
            value: d.id,
            label: d.name,
            detail: d
          });
        });
        this.list.parliamentRegions = options
        this.model.parliamentRegion = resp.data[0].id
        // this.getDapil()
      })
    },
    getDapil () {
      if (this.model.parliamentRegion === this.list.parliamentRegions[2].value && !this.model.city) {
        this.list.dapil = []
        return false;
      }
      dapilModel.list({
        'region_city_id': this.model.city ? this.model.city : '',
        'limit': 100,
        'parliament_region_id': this.model.parliamentRegion
      }).then(resp => {
        const options = []
        resp.data.forEach(d => {
          options.push({
            value: d.id,
            label: d.name
          });
        });
        this.list.dapil = options
        this.model.dapil = resp.data[0].id
      })
    },
    getCities () {
      cityModel.list({
        'name': 'labuhan',
        'province_id': '950b9d3a-c996-4207-97e7-5a2858981979',
        'limit': 10000
      }).then(resp => {
        const options = []
        resp.data.forEach(d => {
          options.push({
            value: d.id,
            label: d.name
          });
        });
        this.list.city = options
      })
    },
    getTotalDPt () {
      dptModel.totalDPt({
        'dapil_id': this.model.dapil,
        'parliament_region_id': this.model.parliamentRegion,
      }).then(resp => {
        this.model.totalDpt = resp
      })
    },
    getTotalVote () {
      this.loading.loadVote = true;
      if (this.model.dapil) {
        partyModel.totalVote({
          'dapil_id': this.model.dapil,
          // 'parliament_region_id': this.model.parliamentRegion,
        }).then(resp => {
          this.model.totalVoteUse = resp
        })
        partyModel.totalVoteByParty({
          'dapil_id': this.model.dapil,
          'parliament_region_id': this.model.parliamentRegion,
        }).then(resp => {
          this.model.party_vote_counts = []
          if (resp.length > 0) {
            this.model.party_vote_counts = resp[0]
            this.getSingleTotalVotes()
          }
          this.loading.loadVote = false;
        }).catch(() => {
          this.model.party_vote_counts = []
          this.loading.loadVote = false;
        })
      }
    },
    getSingleTotalVotes () {
      this.list.parties.forEach(party => {
        const filtered = this.model.party_vote_counts.filter(d => {
          return d.id === party.id
        })
        if (filtered.length > 0) {
          party.total_vote_count = filtered[0].total_vote
        }
      })
    }
  }
}
</script>