<template>
  <div>
    <b-card no-header>
      <b-row v-if="party" class="mb-4">
        <b-col cols="6" md="2">
          <h2 class="m-0 text-md-center text-lg-center" style="font-weight: bold;">
            <img v-if="party.logo && Object.keys(party.logo).length > 0" :src="party.logo['']" alt="" style="max-width: 70px;"> {{ party.name }}
          </h2>
        </b-col>
        <b-col cols="6" class="text-right d-md-none d-lg-none">
          <b-button-group class="add-button-group">
            <b-btn variant="success" type="button" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
          </b-button-group>
        </b-col>
        <b-col cols="12" md="5" class="d-flex align-items-center flex-wrap mt-2 mt-md-0">
          <p class="mb-1 w-100">Nama Caleg: {{ caleg.name }}<br>No. urut:  {{ caleg.number }}<br>DAPIL:  {{ caleg.dapil_name }}<br>Total Suara:  {{ caleg.vote_count }}</p>
        </b-col>
        <b-col cols="5" class="align-items-center flex-wrap justify-content-end d-none d-md-flex">
          <b-button-group class="add-button-group">
            <b-btn variant="success" type="button" @click.prevent="doExport"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
          </b-button-group>
        </b-col>
        <!-- <b-col cols="10">
          <b-row>
            <b-col cols="12" class="text-right">
              <b-button-group class="add-button-group">
                <b-btn variant="success" type="button"><font-awesome-icon icon="file-excel"/> Ekspor</b-btn>
              </b-button-group>
            </b-col>
            <b-col cols="12" class="text-right">
              <b-form>
                <b-row class="filter-row justify-content-end">
                  <b-col cols="4">
                    <form-group-input ref="filter-name" type="text" placeholder="" :need-label="true" label="cari nama caleg" id="filter-name" class="text-left" />
                  </b-col>
                  <b-col cols="2">
                    <form-group-input ref="filter-tps" type="text" class="text-left" placeholder="" :need-label="true" label="TPS" id="filter-tps" />
                  </b-col>
                  <b-col cols="3" style="max-width: 165px;">
                    <label class="form-label">&nbsp;</label><br>
                    <b-btn type="submit" variant="primary" class="mr-2">Cari</b-btn>
                    <b-btn type="button" variant="secondary">Clear</b-btn>
                  </b-col>
                </b-row>
              </b-form>

            </b-col>
          </b-row>
        </b-col> -->
      </b-row>
      
      
    <paginated-table v-if="filter.dapil_id" ref="dataTable" :delete-reason="false" :can-delete="true" :can-edit="false" :data-url="apiUrl.district" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter" :dataLimit="50" :noPushParam="true">
      <template slot="content_name" slot-scope="defaultSlotScope">
        <router-link style="text-decoration: underline;" :to="'/perolehan-suara/calon/' + $route.params.regionId + '/' + $route.params.partyid + '/caleg/' + $route.params.calegId + '/' + defaultSlotScope.colData.id" class="mb-0 text-center detail mr-1">
          {{ defaultSlotScope.colData.name }}
        </router-link>
      </template>
      <template slot="actionButtons" slot-scope="defaultSlotScope">
        <b-row class="justify-content-center table-actions">
          <template>
            <b-button variant="primary" size="sm" :to="'/perolehan-suara/calon/' + $route.params.regionId + '/' + $route.params.partyid + '/caleg/' + $route.params.calegId + '/' + defaultSlotScope.colData.id" class="mb-0 text-center detail mr-1">
              <font-awesome-icon icon="info"/>
            </b-button>
          </template>
        </b-row>
      </template>
    </paginated-table>
    </b-card>
  </div>
</template>

<script>
// import formGroupInput from '@/components/FormGroupInput.vue'
import paginatedTable from '@/components/PaginatedTable.vue'
import Party from '@/models/Party.js'
import City from '@/models/City.js'
import District from '@/models/District.js'
import User from '@/models/User.js'

const partyModel = new Party()
const cityModel = new City()
const districtModel = new District()
const userModel = new User()

export default {
  components: {
    paginatedTable,
    // formGroupInput
  },
  data () {
    return {
      caleg: {
        name: null,
        number: null,
        dapil_name: 0,
        vote_count: null,
      },
      isLoading: false,
      parties: [],
      party: null,
      city: null,
      apiUrl: {
        district: districtModel.endpoint
        // caleg: process.env.VUE_APP_API_URL + 'fake-data/caleg'
      },
      columns: [
        {
          prop: 'name',
          label: 'Kecamatan',
          sortable: true,
          resizable: true,
          minWidth: 200
        },
        {
          prop: 'city_name',
          label: 'Kota',
          sortable: true,
          resizable: true,
          minWidth: 200
        },
        {
          align: 'center',
          prop: 'villages_count',
          label: 'Jlh. Kelurahan',
          resizable: true,
          sortable: false,
          minWidth: 90
        },
        {
          prop: 'vote_count',
          label: 'Jlh. Suara',
          resizable: true,
          sortable: true,
          align: 'center',
          minWidth: 100
        },
      ],
      filter: {
        'with_villages_count': 1,
        'votes_count': 1,
        'dapil_id': null,
        'for_caleg': this.$route.params.calegId,
      },
      dataParams: ['with_villages_count', 'votes_count', 'dapil_id', 'for_caleg']
    }
  },
  mounted () {
    this.getPartyDetail()
    this.getCalegDetail()
    // this.getCityDetail()
  },
  watch: {
    '$route.params.partyid': function (val) {
      if (val !== undefined) {
        this.getPartyDetail()
      }
    },
    '$route.params.cityId': function (val) {
      if (val !== undefined) {
        this.getCityDetail()
      }
    },
  },
  methods: {
    getCalegDetail () {
      userModel.find(this.$route.params.calegId, {
        work_area_only: 1
      }).then(resp => {
        this.filter.dapil_id = resp.user_work_area.dapil.id
        this.caleg.name = resp.name
        this.caleg.number = resp.user_work_area.number
        this.caleg.dapil_name = resp.user_work_area.dapil.name
        this.caleg.vote_count = resp.user_work_area.vote_count
      })
    },
    getPartyDetail () {
      partyModel.find(this.$route.params.partyid).then(resp => {
        this.party = resp
      })
    },
    getCityDetail () {
      cityModel.find(this.$route.params.cityId).then(resp => {
        this.city = resp
      })
    },
    doExport () {
      if (this.isLoading) {
        return false;
      }
      this.isLoading = true;
      const filterArray = [];
      Object.keys(this.filter).forEach(key => {
        if (this.filter[key]) {
          filterArray.push(key + "=" + this.filter[key]);
        }
      });
      filterArray.push('csv=1');

      this.$http.get(districtModel.getEndpoint() + "?" + filterArray.join("&")).then(response => {
        const a = document.createElement("a");

        const blob = new Blob([response.data], {type: "octet/stream"});
        a.href = window.URL.createObjectURL(blob);

        a.download = "data_perolehan_suara_kecamatan_caleg_" + this.caleg.name + ".csv";
        a.click();
        this.isLoading = false;
      }).catch(error => {
        this.$store.dispatch('notification/error', error)
        this.isLoading = false;
      })
    }
  }
}
</script>