import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Dpt extends BaseModel {
  fields =  {
    'nkk': {
      label: 'NKK',
      type: 'text',
      rules: 'required'
    },
    'nik': {
      type: 'text',
      label: 'NIK',
      rules: 'required'
    },
    'name': {
      label: 'Nama',
      type: 'text',
      rules: 'required'
    },
    'phone_number': {
      type: 'text',
      label: 'No. HP',
      rules: 'required'
    },
    'city_id': {
      type: 'enum',
      label: 'Kabupaten',
      rules: 'required',
      options: {}
    },
    'district_id': {
      type: 'enum',
      label: 'Kecamatan',
      rules: 'required',
      options: {}
    },
    'village_id': {
      type: 'enum',
      label: 'Kelurahan',
      rules: 'required',
      options: {}
    },
    'tps': {
      label: 'TPS',
      type: 'number',
      rules: 'required'
    },
    'hometown': {
      type: 'text',
      label: 'Tempat Lahir',
      rules: 'required'
    },
    'age': {
      label: 'Umur',
      type: 'number',
      rules: 'required'
    },
    'marriage_status': {
      type: 'text',
      label: 'Status',
      rules: 'required'
    },
    'gender': {
      type: 'radio',
      label: 'Jekel',
      rules: 'required',
      options: {
        'laki-laki': 'Laki-Laki',
        'perempuan': 'Perempuan',
      }
    },
    'address': {
      type: 'textarea',
      label: 'Alamat',
      rules: 'required'
    }
  }
  endpoint = process.env.VUE_APP_API_URL + 'dpt'

  checkNIK (nik) {
    return new Promise((resolve, reject) => {
      axios.post(this.endpoint + '/check-nik/' + nik).then(response => {
        resolve(response.data ? [
          response.data
        ] : [])
      }).catch(error => {
        reject(error)
      })
    })
  }

  totalDPt (params = {}) {
    return new Promise((resolve, reject) => {
      const url = new URL(this.endpoint)
      const urlParams = new URLSearchParams(url.search)
      Object.keys(params).forEach(key => {
        if (typeof params[key] === 'string' || typeof params[key] === 'number') {
          urlParams.append(key, params[key])
        }
      })
      axios.get(this.endpoint + '/total-dpt?' + urlParams.toString()).then(response => {
        resolve(response.data ? [
          response.data
        ] : [])
      }).catch(error => {
        reject(error)
      })
    })
  }

  attachCaleg (dptId, calegId, additionals) {
      return new Promise((resolve, reject) => {
        axios.post(this.endpoint + '/caleg/attach', {
          dpt_id: dptId,
          caleg_id: calegId,
          additionals: additionals
        }).then(response => {
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
  }
}