<template>
    <div>
        <h5 class="text-left mb-3">
          Pilih salah satu partai
        </h5>
        <b-row class="mb-0">
          <b-col cols="4" md="2" class="mb-3" v-for="party in parties" :key="party.id">
            <router-link :to="'/cek-dokumen/calon/' + $route.params.regionId + '/' + party.id + '?order=number&ascending=1'" style="text-decoration: none !important;">
              <b-card :header="'' + party.sort" class="party-dashboard-list h-100 d-flex align-items-center justify-content-center" body-class="d-flex align-items-center justify-content-center">
                <img :src="party.logo['']" class="img-fluid" alt="">
              </b-card>
            </router-link>
          </b-col>
        </b-row>
        
      
    </div>
  </template>
  
  <script>
  import Party from '@/models/Party.js'
  import ParliamentRegion from '@/models/ParliamentRegion.js'
  
  const partyModel = new Party()
  const parliamentRegionModel = new ParliamentRegion()
  
  export default {
    data () {
      return {
        parties: [],
      }
    },
    mounted () {
      this.getParties()
    //   this.getParliamentRegionDetail()
    //   setTimeout(() => {
    //   this.$route.meta.title = 'Anto Yo'
    //   }, 1500);
    },
    methods: {
      getParties () {
        partyModel.list({
          'limit': 10000
        }).then(resp => {
          this.parties = resp.data
        })
      },
      getParliamentRegionDetail () {
        parliamentRegionModel.find(this.$route.params.regionId).then(resp => {
          // this.$route.meta.title = 'Perolehan Suara ' + resp.name
        })
      },
    }
  }
  </script>