<template>
    <b-modal ref="addNewCaleg" title="Tambah Akses Caleg" hide-footer>
        <div>
            <!-- <label for="">Cari NIK</label> -->
            <v-select
                class="w-100"
                placeholder="Cari Partai" v-model="model.party_id" :reduce="option => option.value" :options="list.parties" :disabled="!list.parties.length" :clearable="false">
            </v-select>
            <v-select
                v-if="parliamentRegion && parliamentRegion.level === 3"
                class="w-100 mt-3"
                placeholder="Cari Kota/Kab" v-model="model.city_id" :reduce="option => option.value" :options="list.cities" :disabled="!list.cities.length" :clearable="false">
            </v-select>
            <v-select
                class="w-100 mt-3"
                placeholder="Cari Dapil" v-model="model.dapil_id" :reduce="option => option.value" :options="list.dapil" :disabled="!list.dapil.length" :clearable="false">
            </v-select>
            <v-select
                class="w-100 mt-3"
                placeholder="Cari Nama" :disabled="!model.party_id || !model.dapil_id" v-model="model.id" :reduce="option => option.value" :options="list.users" @search="doSearch">
                <!-- <template #search="{attributes, events}">
                    <input
                    class="vs__search"
                    :state="rules !== null ? (errors.length ? valid : null) : null"
                    />
                </template> -->
            </v-select>
            <!-- <b-input-group>
                <v-select
                    placeholder="Cari NIK" v-model="model.q" :reduce="option => option.value" @search="doSearch">
                </v-select>
                <b-input-group-append>
                    <b-button variant="outline-primary"><font-awesome-icon icon="search"/><span>Cari</span></b-button>
                </b-input-group-append>
            </b-input-group> -->
        </div>
    </b-modal>
</template>
<script>
import User from '@/models/User.js'
import Party from '@/models/Party.js'
import Dapil from '@/models/Dapil.js'
import City from '@/models/City.js'

const userModel = new User()
const partyModel = new Party()
const dapilModel = new Dapil()
const cityModel = new City()

export default {
  props: ['parliamentRegion'],
  data () {
    return {
        model: {
            id: null,
            party_id: null,
            dapil_id: null,
            city_id: null,
        },
        list: {
            cities: [],
            dapil: [],
            parties: [],
            users: []
        }
    }
  },
  watch: {
    'model.id': function (newValue) {
        if (newValue) {
            this.$router.push({
                path: '/caleg/' + newValue,
                query: {
                    parliament_region_id: this.$route.query.parliament_region_id
                }
            })
        }
    },
    'model.city_id': function (newValue) {
        console.log(newValue)
        if (newValue) {
            this.model.dapil_id = null
            this.getDapil(newValue, this.parliamentRegion.level)
        }
    },
    // 'fileInput': function (newValue, oldValue) {
    //   this.$emit('input', newValue)
    // }
    
},
  methods: {
    show () {
        this.model.id = null
        this.model.dapil_id = null
        this.model.party_id = null
        this.model.city_id = null
        this.list.dapil = []
        this.list.users = []
        this.getParties()
        if (this.parliamentRegion.level !== 3) {
            this.getDapil(null, this.parliamentRegion.level)
        } else {
            this.getCities();
        }
        this.$refs.addNewCaleg.show()
    },
    hide () {
        this.$refs.addNewCaleg.hide()
    },
    getParties () {
        if (this.list.parties.length > 0) {
            return '';
        }
        partyModel.list().then(resp => {
            const options = []
            resp.data.forEach(d => {
            options.push({
                value: d.id,
                label: d.name
            });
            })
            this.list.parties = options
        })
    },
    getCities () {
        if (this.list.cities.length > 0) {
            return '';
        }
        cityModel.list({
            'name': 'labuhan',
            'province_id': '950b9d3a-c996-4207-97e7-5a2858981979',
            'limit': 10000
        }).then(resp => {
            const options = []
            resp.data.forEach(d => {
                options.push({
                    value: d.id,
                    label: d.name
                });
            })
            this.list.cities = options
        })
    },
    getDapil (cityId, regionLevel = 3) {
      if (regionLevel === 3 && !cityId) {
        return []
      }
    dapilModel.list({
        'region_city_id': cityId ? cityId : '',
        'parliament_region_level': regionLevel,
        'limit': 200
    }).then(resp => {
        const options = []
        resp.data.forEach(d => {
        options.push({
            value: d.id,
            detail: d,
            label: d.name
        });
        })
        this.list.dapil = options
    })
    },
    async doSearch (search, loading) {
        loading(true)
        if (search) {
            const userList = await userModel.list({
                roles: 'caleg',
                dapil_id: this.model.dapil_id,
                party_id: this.model.party_id,
                name: search,
                with_user_detail_only: 1,
                limit: 1
            });
            const options = []
            userList.data.forEach(user => {
                options.push({
                    value: user.id,
                    detail: user,
                    // label: 'Nama: ' + user.name + ', NIK: ' + user.user_detail.nik
                    label: 'Nama: ' + user.name
                });
            });
            this.list.users = options
        }
        loading(false)
    }
  }
}
</script>