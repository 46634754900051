<template>
  <div>
    <selected-caleg v-if="typeof this.$route.query.caleg_id !== 'undefined' && typeof this.$route.query.parliament_region_id !== 'undefined'"/>
    <selected-parliament-region v-else-if="typeof this.$route.query.parliament_region_id !== 'undefined'"/>
    <parliament-region-options v-else/>
  </div>
</template>

<script>
import selectedCaleg from './SelectedCaleg.vue'
import selectedParliamentRegion from './SelectedParliamentRegion.vue'
import parliamentRegionOptions from './ParliamentRegionOptions.vue'

export default {
  components: {
    selectedParliamentRegion,
    selectedCaleg,
    parliamentRegionOptions
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    setParams () {
      if (this.$store.getters['account/accountData'].role !== 'caleg') {} else {
        this.$router.push({
          path: '/pendukung',
          query: {
            caleg_id: this.$store.getters['account/accountData'].id,
            dapil_id: this.$store.getters['account/accountData'].dapil.id,
            parliament_region_id: this.$store.getters['account/accountData'].dapil.parliament_region_id,
          }
        }).catch(err => {})
      }
    }
  },
  updated () {
    this.setParams()
  },
  mounted () {
    this.setParams()
  }
}
</script>