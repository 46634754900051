import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Party extends BaseModel {
    endpoint = process.env.VUE_APP_API_URL + 'parties'
    totalVote (params = {}) {
        return new Promise((resolve, reject) => {
          const url = new URL(this.endpoint)
          const urlParams = new URLSearchParams(url.search)
          Object.keys(params).forEach(key => {
            if (typeof params[key] === 'string' || typeof params[key] === 'number') {
              urlParams.append(key, params[key])
            }
          })
          axios.get(this.endpoint + '/total-vote?' + urlParams.toString()).then(response => {
            resolve(response.data ? [
              response.data
            ] : [])
          }).catch(error => {
            reject(error)
          })
        })
      }
      totalVoteByParty (params = {}) {
          return new Promise((resolve, reject) => {
            const url = new URL(this.endpoint)
            const urlParams = new URLSearchParams(url.search)
            Object.keys(params).forEach(key => {
              if (typeof params[key] === 'string' || typeof params[key] === 'number') {
                urlParams.append(key, params[key])
              }
            })
            axios.get(this.endpoint + '/total-parties-vote?' + urlParams.toString()).then(response => {
                // console.log("response")
                // console.log(response.data)
              resolve(response.data ? [
                response.data.length > 0 ? response.data : []
              ] : [])
            }).catch(error => {
              reject(error)
            })
          })
        }
}