<template>
    <b-card class="mb-4" no-header>
      <!-- <template #header>
        <b-row>
          <b-col cols="10">
            <h5 class="mb-0">{{$route.name}}</h5>
          </b-col>
          <b-col cols="2" class="text-right">
            <b-button-group class="add-button-group">
              <b-btn variant="primary" href="/caleg/create">Tambah Data</b-btn>
            </b-button-group>
          </b-col>
        </b-row>
      </template> -->
      <b-row>
        <b-col cols="12">
          <div v-if="detail.parliamentRegion" class="mb-2">
            <h4 class="m-0">{{ detail.parliamentRegion.name }}</h4>
            <p class="m-0">Silahkan pilih salah satu caleg</p>
          </div>
        </b-col>
        <b-col cols="12" class="mb-4" v-if="detail.parliamentRegion">
          <b-form @submit.prevent="doFilter">
          <template v-if="detail.parliamentRegion.level === 3">
            <b-row class="filter-row">
              <b-col cols="6" md="4">
                <form-group-input ref="filter-name" type="text" placeholder="" :need-label="true" label="nama" v-model="filter.name" id="filter-name" />
              </b-col>
              <b-col cols="6" md="3">
                <form-group-input ref="filter-party_id" type="enum" :options="list.party_id" placeholder="" :need-label="true" label="Partai" v-model="filter.party_id" id="filter-party_id" />
              </b-col>
              <b-col cols="4" md="1">
                <form-group-input ref="filter-number" type="text" placeholder="" :need-label="true" label="No.Urut" v-model="filter.number" id="filter-number" />
              </b-col>
              <b-col cols="6" md="3">
                <form-group-input ref="filter-work_area_city_id" type="enum" :options="list.work_area_city" placeholder="" :need-label="true" label="Kabupaten" v-model="filter.work_area_city_id" id="filter-work_area_city_id" />
              </b-col>
            </b-row>
            <b-row class="filter-row">
                <b-col cols="6" md="3">
                  <form-group-input ref="filter-dapil_id" type="enum" :options="list.dapil" placeholder="" :need-label="true" label="Dapil" v-model="filter.dapil_id" id="filter-dapil_id" />
                </b-col>
                <!-- <b-col cols="6" md="3">
                  <form-group-input ref="filter-work_area_district_id" type="enum" :options="list.work_area_district" placeholder="" :need-label="true" label="Kecamatan" v-model="filter.work_area_district_id" id="filter-work_area_district_id" />
                </b-col>
                <b-col cols="6" md="3">
                  <form-group-input ref="filter-work_area_village_id" type="enum" :options="list.work_area_village" placeholder="" :need-label="true" label="Kelurahan" v-model="filter.work_area_village_id" id="filter-work_area_village_id" />
                </b-col> -->
              <b-col cols="6" md="2">
                <label class="form-label">&nbsp;</label><br>
                <b-btn type="submit" class="btn-in-form" variant="primary">
                  <font-awesome-icon icon="search"/><span>Cari</span>
                </b-btn>
                <b-btn type="button" @click.prevent="clearForm" variant="secondary" class="btn-in-form ml-2">
                  <font-awesome-icon icon="broom"/><span>Clear</span>
                </b-btn>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <b-row class="filter-row">
              <b-col cols="5" md="3">
                <form-group-input ref="filter-name" type="text" placeholder="" :need-label="true" label="nama" v-model="filter.name" id="filter-name" />
              </b-col>
              <b-col cols="7" md="3">
                <form-group-input ref="filter-party_id" type="enum" :options="list.party_id" placeholder="" :need-label="true" label="Partai" v-model="filter.party_id" id="filter-party_id" />
              </b-col>
              <b-col cols="4" md="1">
                <form-group-input ref="filter-number" type="text" placeholder="" :need-label="true" label="No.Urut" v-model="filter.number" id="filter-number" />
              </b-col>
              <b-col cols="8" md="3">
                <form-group-input ref="filter-dapil_id" type="enum" :options="list.dapil" placeholder="" :need-label="true" label="Dapil" v-model="filter.dapil_id" id="filter-dapil_id" />
              </b-col>
              <!-- <b-col cols="4" md="1">
                <form-group-input ref="filter-tps" type="text" placeholder="" :need-label="true" label="tps" v-model="filter.tps" id="filter-tps" />
              </b-col> -->
              <b-col cols="6" md="2">
                <label class="form-label">&nbsp;</label><br>
                <b-btn type="submit" class="btn-in-form" variant="primary">
                  <font-awesome-icon icon="search"/><span>Cari</span>
                </b-btn>
                <b-btn type="button" @click.prevent="clearForm" variant="secondary" class="btn-in-form ml-2">
                  <font-awesome-icon icon="broom"/><span>Clear</span>
                </b-btn>
              </b-col>
            </b-row>
          </template>
          </b-form>
        </b-col>
      </b-row>
      <paginated-table ref="dataTable" :delete-reason="false" :can-delete="true" :can-edit="true" :data-url="apiUrl.user" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter">
        <!-- <template slot="content_role" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.role !== null ? defaultSlotScope.colData.role.replace('-', ' ') : ''}}</span>
        </template> -->
        <template slot="content_phone_number" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_detail !== null && defaultSlotScope.colData.user_detail.phone_number? defaultSlotScope.colData.user_detail.phone_number.replace('+62', '0') : '-'}}</span>
        </template>
        <template slot="content_vote_count" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{ defaultSlotScope.colData.user_work_area.vote_count }}</span>
        </template>
        <template slot="content_dapil" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_work_area !== null && defaultSlotScope.colData.user_work_area.dapil !== null ? defaultSlotScope.colData.user_work_area.dapil.name : ''}}</span>
        </template>
        <template slot="content_work_area_city_id" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_work_area !== null && defaultSlotScope.colData.user_work_area.work_area_city_id !== null ? defaultSlotScope.colData.user_work_area.city.name : ''}}</span>
        </template>
        <template slot="content_work_area_district_id" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_work_area !== null && defaultSlotScope.colData.user_work_area.work_area_district_id !== null ? defaultSlotScope.colData.user_work_area.district.name : ''}}</span>
        </template>
        <template slot="content_work_area_village_id" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_work_area !== null && defaultSlotScope.colData.user_work_area.work_area_village_id !== null ? defaultSlotScope.colData.user_work_area.village.name : ''}}</span>
        </template>
        <template slot="content_number" slot-scope="defaultSlotScope">
          <span>{{defaultSlotScope.colData.user_work_area.number}}</span>
        </template>
        <template slot="content_party_id" slot-scope="defaultSlotScope">
          <span>{{defaultSlotScope.colData.user_work_area !== null && defaultSlotScope.colData.user_work_area.party_id !== null ? defaultSlotScope.colData.user_work_area.party.name : ''}}</span>
        </template>
        <template slot="content_tps" slot-scope="defaultSlotScope">
          <span style="text-transform: capitalize;">{{defaultSlotScope.colData.user_work_area !== null ? defaultSlotScope.colData.user_work_area.tps : ''}}</span>
        </template>
        <template slot="content_name" slot-scope="defaultSlotScope">
            <router-link style="text-decoration: underline;" :to="'/pendukung?parliament_region_id=' + filter.parliament_region_id + '&caleg_id=' + defaultSlotScope.colData.id + '&dapil_id=' + defaultSlotScope.colData.user_work_area.dapil_id">{{defaultSlotScope.colData.name}}</router-link>
        </template>
        <template slot="actionButtons" slot-scope="defaultSlotScope">
            <b-row class="justify-content-center table-actions">
            <template>
                <b-button variant="primary" size="sm" :to="'/pendukung?parliament_region_id=' + filter.parliament_region_id + '&caleg_id=' + defaultSlotScope.colData.id + '&dapil_id=' + defaultSlotScope.colData.user_work_area.dapil_id" class="mb-0 text-center detail mr-1">
                <font-awesome-icon icon="info"/>
                </b-button>
            </template>
            </b-row>
        </template>
      </paginated-table>
    </b-card>
  </template>
  
  <script>
  import { objectToOptions, classicObjectToOptions } from '@/_helpers'
  // import { objectToOptions, getYears, formatModelValue } from '@/_helpers'
  import paginatedTable from '@/components/PaginatedTable.vue'
  import formGroupInput from '@/components/FormGroupInput.vue'
  import User from '@/models/User.js'
  import City from '@/models/City.js'
  import District from '@/models/District.js'
  import Village from '@/models/Village.js'
  import Party from '@/models/Party.js'
  import ParliamentRegion from '@/models/ParliamentRegion.js'
  import Dapil from '@/models/Dapil.js'
  
  const userModel = new User()
  const cityModel = new City()
  const districtModel = new District()
  const villageModel = new Village()
  const partyModel = new Party()
  const parliamentRegionModel = new ParliamentRegion()
  const dapilModel = new Dapil()
  
  export default {
    components: {
      formGroupInput,
      paginatedTable
    },
    data () {
      return {
        isLoading: false,
        totalPromiseAreaCount: 0,
        apiUrl: {
          user: userModel.endpoint
        },
        columns: [
          {
            prop: 'name',
            label: 'Nama',
            sortable: true,
            resizable: true,
            minWidth: 200,
            // wrapInLink: true,
          },
          {
            prop: 'party_id',
            label: 'Partai',
            resizable: true,
            sortable: true,
            minWidth: 150
          },
          {
            prop: 'number',
            label: 'No. Urut',
            resizable: true,
            sortable: true,
            align: 'center',
            minWidth: 100
          },
          // {
          //   prop: 'password',
          //   label: 'Password',
          //   resizable: true,
          //   sortable: true,
          //   minWidth: 150
          // },
          {
            align: 'center',
            prop: 'dapil',
            label: 'DAPIL',
            resizable: true,
            sortable: true,
            minWidth: 80
          },
          {
            prop: 'vote_count',
            label: 'Suara',
            resizable: true,
            sortable: true,
            align: 'center',
            minWidth: 80
          },
          {
            prop: 'phone_number',
            label: 'Kontak',
            resizable: true,
            sortable: true,
            minWidth: 120
          },
          // {
          //   prop: 'role',
          //   label: 'Peran',
          //   resizable: true,
          //   sortable: true,
          //   minWidth: 125
          // },
          {
            prop: 'work_area_city_id',
            label: 'Kabupaten',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            prop: 'work_area_district_id',
            label: 'Kecamatan',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            prop: 'work_area_village_id',
            label: 'Kelurahan',
            resizable: true,
            sortable: true,
            minWidth: 210
          },
          {
            align: 'center',
            prop: 'tps',
            label: 'TPS',
            resizable: true,
            sortable: true,
            minWidth: 80
          },
          {
            prop: 'is_active',
            label: 'Status',
            isToggle: true,
            resizable: true,
            sortable: true,
            minWidth: 80
          }
        ],
        detail: {
          parliamentRegion: null
        },
        list: {
          work_area_city: [],
          work_area_district: [],
          work_area_village: [],
          party_id: [],
          dapil: [],
        },
        filter: {
          with_user_detail: 1,
          only_has_access: 1,
          with_user_work_area: 1,
          name: typeof this.$route.query.name !== 'undefined' ? this.$route.query.name : null,
          party_id: typeof this.$route.query.party_id !== 'undefined' ? this.$route.query.party_id : null,
          number: typeof this.$route.query.number !== 'undefined' ? this.$route.query.number : null,
          dapil_id: typeof this.$route.query.dapil_id !== 'undefined' ? this.$route.query.dapil_id : null,
          tps: typeof this.$route.query.tps !== 'undefined' ? this.$route.query.tps : null,
          work_area_city_id: typeof this.$route.query.work_area_city_id !== 'undefined' ? this.$route.query.work_area_city_id : null,
          work_area_district_id: typeof this.$route.query.work_area_district_id !== 'undefined' ? this.$route.query.work_area_district_id : null,
          work_area_village_id: typeof this.$route.query.work_area_village_id !== 'undefined' ? this.$route.query.work_area_village_id : null,
          parliament_region_id: typeof this.$route.query.parliament_region_id !== 'undefined' ? this.$route.query.parliament_region_id : null,
          roles: 'caleg',
        },
        dataParams: ['with_user_detail', 'with_user_work_area', 'name', 'dapil_id', 'tps', 'work_area_city_id', 'work_area_district_id', 'work_area_village_id', 'roles', 'number', 'party_id', 'only_has_access', 'parliament_region_id']
      }
    },
    beforeMount () {
      this.$router.push({query: {...this.$route.query}}).catch(err => {})
    },
    async mounted () {
      if (typeof this.$route.query.work_area_city_id !== 'undefined') {
        this.totalPromiseAreaCount++;
        this.list.work_area_district = objectToOptions(await this.getDistricts(this.$route.query.work_area_city_id))
      }
      if (typeof this.$route.query.work_area_district_id !== 'undefined') {
        this.totalPromiseAreaCount++;
        this.list.work_area_village = objectToOptions(await this.getVillages(this.$route.query.work_area_district_id)) 
      }
      
      this.getCities()
      this.getParty()
      this.getParliamentRegionDetail()
    },
    watch: {
      'filter.work_area_city_id': async function (val, oldVal) {
        if (val) {
          this.list.work_area_district = objectToOptions(await this.getDistricts(val))
          this.list.dapil = objectToOptions(await this.getDapil(val))
          if (val !== oldVal) {
            this.totalPromiseAreaCount++;
            this.filter.dapil_id = null
            this.filter.work_area_district_id = null
          }
        } else {
          this.filter.dapil_id = null
          this.filter.work_area_district_id = null
        }
      },
      'filter.work_area_district_id': async function (val, oldVal) {
        if (val) {
          this.list.work_area_village = objectToOptions(await this.getVillages(val))
          if (val !== oldVal) {
          this.totalPromiseAreaCount++;
            this.filter.work_area_village_id = null
          }
        } else {
          this.filter.work_area_village_id = null
        }
      },
      'filter.parliament_region_id': function (val) {
        this.getParliamentRegionDetail()
      },
      'totalPromiseAreaCount': async function (val) {
        if (val >= 4) {
          this.initEdit = false;
        }
      },
    },
    methods: {
      getParty () {
        partyModel.list({
          'limit': 10000
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          this.list.party_id = objectToOptions(data)
        })
      },
      getCities () {
        cityModel.list({
          'name': 'labuhan',
          'province_id': '950b9d3a-c996-4207-97e7-5a2858981979',
          'limit': 10000
        }).then(resp => {
          let data = []
          // data[null] = 'Tidak Ada'
          resp.data.forEach(d => {
            data[d.id] = d.name
          })
          this.list.work_area_city = objectToOptions(data)
        })
      },
      async getDistricts (cityId) {
        return new Promise(resolve => {
          districtModel.list({
            'city_id': cityId
          }).then(resp => {
            let data = []
            // data[null] = 'Tidak Ada'
            resp.data.forEach(d => {
              data[d.id] = d.name
            })
            resolve(data);
            // this.userModel.district_id.options = data
          })
        })
      },
      async getVillages (districtId) {
        return new Promise(resolve => {
          villageModel.list({
            'district_id': districtId,
          }).then(resp => {
            let data = []
            // data[null] = 'Tidak Ada'
            resp.data.forEach(d => {
              data[d.id] = d.name
            })
            resolve(data);
            // this.userModel.village_id.options = data
          })
        })
      },
      clearForm () {
        this.$nextTick(() => {
          this.filter = {
            roles: 'caleg',
            with_user_detail: 1,
            only_has_access: 1,
            with_user_work_area: 1,
            name: null,
            dapil_id: null,
            party_id: null,
            number: null,
            tps: null,
            work_area_city_id: null,
            work_area_district_id: null,
            work_area_village_id: null,
            // caleg_id: this.$store.getters['account/accountData'].id,
            // dapil_id: this.$store.getters['account/accountData'].dapil.id,
            parliament_region_id: this.filter.parliament_region_id,
          }
          setTimeout(() => {
            this.doFilter()
          }, 100)
        })
      },
      async getParliamentRegionDetail () {
        parliamentRegionModel.find(this.filter.parliament_region_id).then(async resp => {
          this.detail.parliamentRegion = resp
          this.list.dapil = objectToOptions(await this.getDapil(resp.level === 3 ? this.$route.query.work_area_city_id : '', resp.level))
        })
      },
      async getDapil (cityId, regionLevel = 3) {
        if (regionLevel === 3 && !cityId) {
          return []
        }
        return new Promise(resolve => {
          dapilModel.list({
            'region_city_id': cityId ? cityId : '',
            'parliament_region_level': regionLevel,
            'limit': 200
          }).then(resp => {
            let data = []
            // data[null] = 'Tidak Ada'
            resp.data.forEach(d => {
              data[d.id] = d.name
            })
            resolve(data);
            // this.userModel.village_id.options = data
          })
        })
      },
      doFilter () {
        this.$refs.dataTable.doFilter(this.filter)
      },
      doExport () {
        if (this.isLoading) {
          return false;
        }
        this.isLoading = true;
        const filterArray = [];
        Object.keys(this.filter).forEach(key => {
          if (this.filter[key]) {
            filterArray.push(key + "=" + this.filter[key]);
          }
        });
        filterArray.push('csv=1');
  
        this.$http.get(userModel.getEndpoint() + "?" + filterArray.join("&")).then(response => {
          const a = document.createElement("a");
  
          const blob = new Blob([response.data], {type: "octet/stream"});
          a.href = window.URL.createObjectURL(blob);
  
          a.download = "data_pengguna.csv";
          a.click();
          this.isLoading = false;
        }).catch(error => {
          this.$store.dispatch('notification/error', error)
          this.isLoading = false;
        })
      }
    }
  }
  </script>