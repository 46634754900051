<template>
    <div>
    <b-modal ref="calegDpt" title="Didukung" hide-footer>
        <div>
            <b-row v-if="model.id">
                <b-col cols="6">
                    <p class="m-0">NKK: <strong>{{model.nik}}</strong></p>
                </b-col>
                <b-col cols="6">
                    <p class="m-0">Nama: <strong>{{model.name}}</strong></p>
                </b-col>
                <b-col cols="12">
                    <h5 class="mt-1 mb-1">Caleg Didukung:</h5>
                    <table class="table table-stripped">
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Partai</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="caleg in model.caleg" :key="caleg.id">
                                <td>{{ caleg.name }}</td>
                                <td>{{ caleg.user_work_area.party.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</div>
</template>
<script>
import Pendukung from '@/models/Pendukung.js'

const pendukungModel = new Pendukung()

export default {
  data () {
    return {
        isLoading: false,
        model: {
            id: null,
            name: this.$route.query.caleg_id,
            nik: null,
            caleg: [],
        }
    }
  },
  mounted () {
  },
  methods: {
    show (id) {
        this.$refs.calegDpt.show()
        this.getDetail(id)
        this.model.id = null;
    },
    async getDetail (id) {
        this.isLoading = true;
        try {
            const resp = await pendukungModel.find(id);
            const caleg = await pendukungModel.getCalegDetail(id);
            this.model.id = resp.id
            this.model.nik = resp.nik
            this.model.name = resp.name
            this.model.caleg = caleg
        } catch (e) {
            console.error(e)
            this.$store.dispatch('notification/error', e)
        }
        this.isLoading = false;
    },
  },
}
</script>