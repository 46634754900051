<template>
  <div>
    <b-card no-header>
      <b-row>
        <b-col cols="12" md="6">
          <h5>Informasi Pribadi</h5>
          <template v-for="(m, index) in filterModel(['name', 'nik', 'gender', 'hometown', 'dob', 'city_id', 'district_id', 'village_id', 'address', 'phone_number', 'id_card', 'avatar'])">
            <div :key="'pribadi_' + index">
              <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" />
            </div>            
          </template>
        </b-col>
        <b-col cols="12" md="6">
          <template v-if="model.username.value">
            <h5>Informasi Akun</h5>
            <template v-for="(m, index) in filterModel(['username'])">
              <div :key="'akun_' + index">
                <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" />
              </div>
            </template>
          </template>
          <h5>Informasi Caleg</h5>
          <template v-for="(m, index) in filterModel(model.dapil_id.value ? ['party_id', 'dapil_id', 'work_area_city_id', 'work_area_district_id', 'work_area_village_id', 'number'] : ['party_id', 'number'])">
            <div :key="'tugas_' + index">
              <detail-group-input :ref="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :need-label="true" v-model="m.value" :id="index" />
            </div>
          </template>
        </b-col>
      </b-row>
      <div>
        <b-button :to="'/caleg/' + $route.params.id + '/edit' + (parliament_region_id && model.username.value === null ? '?parliament_region_id=' + parliament_region_id : '')" variant="primary" class="mt-0">{{ model.username.value === null ? 'Beri Akses' : 'Ubah' }}</b-button>
        <b-button :to="'/caleg' + (parliament_region_id ? '?parliament_region_id=' + parliament_region_id : '') " variant="secondary" class="ml-3 float-right">Kembali</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { setModelValue } from '@/_helpers'
import detailGroupInput from '@/components/DetailGroupInput.vue'
import User from '@/models/User.js'
import Dapil from '@/models/Dapil.js'

const userModel = new User()
const dapilModel = new Dapil()

export default {
  components: {
    detailGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (val !== undefined) {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      parliament_region_id: typeof this.$route.query.parliament_region_id !== 'undefined' ? this.$route.query.parliament_region_id : null,
      model: userModel.init(),
      detail: {
        dapil: null
      }
    }
  },
  mounted () {
    this.model.password.type = 'hidden'
    this.getDetail()
  },
  methods: {
    filterModel (search) {
      const filtered = Object.entries(this.model).filter(mdl => {
        return search.includes(mdl[0])
      });
      let result = {}
      filtered.forEach(element => {
        result[element[0]] = element[1];
      });
      return result;
    },
    getDetail () {
      this.isLoading = true
      userModel.find(this.$route.params.id, {
        detail: 1
      }).then(async resp => {
        if (resp.user_work_area.dapil_id) {
          const dapil = await dapilModel.find(resp.user_work_area.dapil_id)
          this.parliament_region_id = dapil.parliament_region_id
        }

        this.isLoading = false
        let response = {}
        // response = resp
        Object.keys(resp.user_detail).forEach(key => {
          if (key === 'gender') {
            response[key] = resp.user_detail[key] === 'laki-laki' ? 'Laki-Laki' : 'Perempuan'
          } else if (key === 'city_id') {
            response[key] = resp.user_detail['city'] ? resp.user_detail['city'].name : ''
          } else if (key === 'district_id') {
            response[key] = resp.user_detail['district'] ? resp.user_detail['district'].name : ''
          } else if (key === 'village_id') {
            response[key] = resp.user_detail['village'] ? resp.user_detail['village'].name : ''
          } else if (key === 'province_id') {
            response[key] = resp.user_detail['province'] ? resp.user_detail['province'].name : ''
          } else if (!['user_id', 'village', 'city', 'district', 'province'].includes(key)) {
            response[key] = resp.user_detail[key]
          }
        });
        Object.keys(resp.user_work_area).forEach(key => {
          if (key === 'work_area_city_id') {
            response[key] = resp.user_work_area['city'] ? resp.user_work_area['dapil'].name :'-'
          } else if (key === 'dapil_id') {
            response[key] = resp.user_work_area['dapil'] ? resp.user_work_area['dapil'].name :'-'
          } else if (key === 'work_area_district_id') {
            response[key] = resp.user_work_area['district'] ? resp.user_work_area['district'].name : '-'
          } else if (key === 'work_area_village_id') {
            response[key] = resp.user_work_area['village'] ? resp.user_work_area['village'].name : '-'
          } else if (key === 'work_area_province_id') {
            response[key] = resp.user_work_area['province'].name
          } else if (key === 'party_id') {
            response[key] = resp.user_work_area['party'].name
          } else if (!['user_id', 'village', 'city', 'district', 'province'].includes(key)) {
            response[key] = resp.user_work_area[key]
          }
        });
        Object.keys(resp).forEach(key => {
          if (key === 'role') {
            response[key] = response[key] === 'petugas-tps' ? 'Petugas TPS' : 'Verifikator'
          } else if (!['user_detail', 'user_work_area'].includes(key)) {
            response[key] = resp[key]
          }
        });
        response['phone_number'] = response['phone_number'] ? response['phone_number'].replace('+62', '0') : '-';
        setModelValue(this.model, response)
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        this.isLoading = false
      })
    }
  }
}
</script>